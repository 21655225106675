import { Check } from "../../icons/check";
import { Close } from "../../icons/close";
import { Spinner } from "../../icons/spinner";

interface Alert {
  title: string;
  subtitle?: string;
  type?: "info" | "error" | "success";
  icon?: any;
}

const Alert = ({ title, subtitle, type = "info", icon }: Alert) => {
  const returnIcon = () => {
    switch (type) {
      case "info":
        return <Spinner size={30} className="animate-spin flex-shrink-0" />;
      case "error":
        return <Close className="flex-shrink-0 text-red-600" />;
      case "success":
        return <Check className="flex-shrink-0 text-green-600" />;
    }
  };

  const returnBackgroundStyle = () => {
    switch (type) {
      case "info":
        return "bg-neutral-100";
      case "error":
        return "bg-red-100";
      case "success":
        return "bg-green-100";
    }
  };

  const returnCaptionStyle = () => {
    switch (type) {
      case "info":
        return "text-neutral-800";
      case "error":
        return "text-red-800";
      case "success":
        return "text-green-800";
    }
  };

  return (
    <div className={`${returnBackgroundStyle()} p-3 rounded-xl`}>
      <div className="flex items-start gap-1">
        {icon ? icon : returnIcon()}
        <div>
          <p className="text-p-s dark:text-neutral-900 font-medium">{title}</p>
          <p className={`text-p-xs ${returnCaptionStyle()}`}>{subtitle}</p>
        </div>
      </div>
    </div>
  );
};

export default Alert;
