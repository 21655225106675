import { DEFAULT_CURRENCY_DECIMAL_PRECISION } from "@/client/lib/numbers";
import { formatUnits } from "viem";

const DEFAULT_MAX_DECIMAL_DIGITS = 2;
const DEFAULT_CURRENCY_CODE = "USD";

/**
 * Component to show the currency icon and formatted amount
 *
 * If passing a bigint assumes DEFAULT_CURRENCY_DECIMAL_PRECISION to convert
 */
export const FormatCurrency = ({
  amount,
  maximumDecimalDigits = DEFAULT_MAX_DECIMAL_DIGITS,
  currencyCode = DEFAULT_CURRENCY_CODE,
}: {
  amount?: string | bigint | number;
  maximumDecimalDigits?: number;
  currencyCode?: string;
}) => {
  let formattedValue = "";

  let parsedAmount;
  if (typeof amount === "bigint") {
    parsedAmount = formatUnits(amount, DEFAULT_CURRENCY_DECIMAL_PRECISION);
  } else {
    parsedAmount = amount;
  }

  if (parsedAmount) {
    const lowestValue = Number(
      `0.${new Array(maximumDecimalDigits).join("0")}1`
    );
    const tooLow = +parsedAmount < lowestValue;
    const formatted = new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: currencyCode,
    }).format(tooLow ? lowestValue : +parsedAmount);

    formattedValue = formatted;
  }

  return <p>{formattedValue}</p>;
};
