import ApprovePending from "client/components/common/modal/ApprovePending";
import SignPending from "@/client/components/common/modal/SignPending";
import { ConnectWallet } from "client/components/common/modal/ConnectWallet";
import ErrorComponent from "client/components/common/modal/ErrorComponent";
import SetDetails from "./stages/SetDetails";
import { DateOption } from "client/components/common/modal/DateDropdown";
import { Stage } from "./stage";
import Complete from "./stages/Complete";
import { Market } from "@/shared/marketplaces";

export const BidComponentBody = ({
  tokenId,
  contractAddress,
  name,
  imageUrl,
  artistName,
  bidAmount,
  bidAmountUsd,
  lastPrice,
  collectionFloor,
  royaltyBps,
  topTraitFloor,
  topBidPrice,
  stage,
  onPriceChanged,
  onSubmitOfferClicked,
  onExpirationDateChanged,
  expirationDateOptions,
  selectedExpirationDateOption,
  isFlagged,
  hasEnoughWEth,
  currentUserAddress,
  error,
}: {
  contractAddress?: string;
  tokenId?: string;
  stage: Stage;
  name?: string;
  imageUrl?: string;
  artistName?: string;
  bidAmount?: string;
  bidAmountUsd?: number;
  lastPrice?: number;
  collectionFloor?: number;
  royaltyBps?: number;
  topTraitFloor?: number;
  topBidPrice?: number;
  onPriceChanged?: (price: string) => void;
  onSubmitOfferClicked?: () => void;
  expirationDateOptions: DateOption[];
  selectedExpirationDateOption: DateOption;
  onExpirationDateChanged: (dateOption: DateOption) => void;
  isFlagged?: boolean;
  currentUserAddress?: string;
  hasEnoughWEth?: boolean;
  error?: Error | null;
}) => {
  const renderBody = () => {
    if (!currentUserAddress) return <ConnectWallet />;

    switch (stage) {
      case Stage.SetDetails:
        return (
          <SetDetails
            imageUrl={imageUrl}
            name={name}
            artistName={artistName}
            price={bidAmount}
            priceUsd={bidAmountUsd}
            lastPrice={lastPrice}
            collectionFloor={collectionFloor}
            royaltyBps={royaltyBps}
            topTraitFloor={topTraitFloor}
            topBidPrice={topBidPrice}
            onPriceChanged={onPriceChanged}
            onSubmitOfferClicked={onSubmitOfferClicked}
            onExpirationDateChanged={onExpirationDateChanged}
            expirationDateOptions={expirationDateOptions}
            selectedExpirationDateOption={selectedExpirationDateOption}
          />
        );
      case Stage.Approve:
        return <ApprovePending market={Market.Sansa} />;
      case Stage.Sign:
        return (
          <SignPending
            market={Market.Sansa}
            marketPrice={bidAmount ? Number(bidAmount) : undefined}
            marketPriceCurrencySymbol={"WETH"}
            imageUrl={imageUrl}
            name={name}
            artistName={artistName}
          />
        );
      case Stage.Complete:
        return (
          <Complete
            contractAddress={contractAddress}
            tokenId={tokenId}
            imageUrl={imageUrl}
            name={name}
            artistName={artistName}
            currentUserAddress={currentUserAddress}
          />
        );
    }
  };

  return (
    <>
      {bidAmount && !hasEnoughWEth ? (
        <div className="px-8 pb-6">
          <ErrorComponent error={"Not enough WETH for offer"} />
        </div>
      ) : null}
      {error ? (
        <div className="px-8 pb-6">
          <ErrorComponent error={error?.message} />
        </div>
      ) : null}
      {isFlagged ? (
        <div className="px-8 pb-6">
          <ErrorComponent
            error={"Warning: This item has been flagged on OpenSea"}
          />
        </div>
      ) : null}
      {renderBody()}
    </>
  );
};
