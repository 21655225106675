import Link from "next/link";

import AssetSummary from "client/components/common/modal/AssetSummary";
import { Check } from "client/components/icons/check";

export const Complete = ({
  contractAddress,
  tokenId,
  imageUrl,
  name,
  artistName,
  currentUserAddress,
}: {
  contractAddress?: string;
  tokenId?: string;
  imageUrl?: string;
  name?: string;
  artistName?: string;
  lastPrice?: number;
  collectionFloor?: number;
  currentUserAddress?: string;
}) => {
  return <>
    <AssetSummary imageUrl={imageUrl} name={name} artistName={artistName} />
    <div className="border-t dark:border-neutral-700 px-6 py-6 flex items-center justify-between">
      <div className="flex dark:text-green-400 text-green-500 items-center gap-3">
        <div className="items-center flex flex-col justify-center rounded-full h-8 w-8 dark:bg-green-900 bg-green-100">
          <Check />
        </div>{" "}
        Offer Submited
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex items-center gap-4">
          <Link
            href={`/asset/${contractAddress}/${tokenId}`}
            className="bg-white dark:bg-neutral-800 dark:text-white text-black px-6 p-3 transition hover:opacity-80 hover:shadow-xl shadow-sm rounded-md">
            
              View Asset
            
          </Link>
          <Link
            href={`/wallets/${currentUserAddress}?tab=offers`}
            className="bg-white dark:bg-neutral-700 dark:text-white text-black px-6 p-3 transition hover:opacity-80 hover:shadow-xl shadow-sm rounded-md">
            
              Manage Offers
            
          </Link>
        </div>
      </div>
    </div>
  </>;
};

export default Complete;
