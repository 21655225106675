import { numberWithCommas } from "client/lib/helpers";
import { Weth } from "client/components/icons/weth";
import {
  DateDropdown,
  DateOption,
} from "client/components/common/modal/DateDropdown";
import AssetSummary from "client/components/common/modal/AssetSummary";
import CrossPostOpenSeaInfo from "../../CrossPostOpenSeaInfo";
import { getBasePath } from "@/shared/config";

export const SetDetails = ({
  imageUrl,
  name,
  artistName,
  price,
  priceUsd,
  lastPrice,
  collectionFloor,
  royaltyBps,
  topTraitFloor,
  topBidPrice,
  onSubmitOfferClicked,
  onPriceChanged,
  expirationDateOptions,
  selectedExpirationDateOption,
  onExpirationDateChanged,
}: {
  imageUrl?: string;
  name?: string;
  artistName?: string;
  price?: string;
  priceUsd?: number;
  lastPrice?: number;
  collectionFloor?: number;
  royaltyBps?: number;
  topTraitFloor?: number;
  topBidPrice?: number;
  onSubmitOfferClicked?: () => void;
  onPriceChanged?: (price: string) => void;
  expirationDateOptions: DateOption[];
  selectedExpirationDateOption: DateOption;
  onExpirationDateChanged: (expirationOption: DateOption) => void;
}) => {
  const handleSubmitOfferClicked = () => {
    if (onSubmitOfferClicked) onSubmitOfferClicked();
  };

  return (
    <>
      <AssetSummary
        imageUrl={imageUrl}
        name={name}
        artistName={artistName}
        royaltyBps={royaltyBps}
        stats={{
          collectionFloor,
          topTraitFloor,
          lastPrice,
          topBidPrice,
        }}
      />
      <div className="px-8 py-6">
        <div className="flex flex-col gap-6">
          <div className="relative flex items-start">
            <SansaPriceSection
              price={price}
              priceUsd={priceUsd}
              onPriceChanged={onPriceChanged}
            />
          </div>
          {/* <div className="relative flex items-start">
            <CrossPostOpenSeaInfo mode="OFFER" />
          </div> */}
        </div>
        <div className="py-6 flex items-center gap-6">
          <div>
            <div className="flex items-center gap-4">
              <DateDropdown
                options={expirationDateOptions}
                onSelectedOptionChanged={onExpirationDateChanged}
                selectedOption={selectedExpirationDateOption}
              />
            </div>
          </div>
          <div className="flex-grow">
            <button
              onClick={handleSubmitOfferClicked}
              className="w-full h-14 dark:bg-white dark:text-black dark:hover:bg-neutral-300 hover:bg-neutral-700 text-white bg-black rounded-full font-medium"
            >
              Submit Offer
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const SansaPriceSection = ({
  price,
  priceUsd,
  onPriceChanged,
}: {
  price?: string;
  priceUsd?: number;
  onPriceChanged?: (price: string) => void;
}) => {
  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center gap-2">
        <img className="h-10 w-10" src={`${getBasePath()}/market-icon.svg`} />
        <div className="flex flex-col">
          <p>Art Blocks</p>
          <p className="text-xs text-neutral-500">0% Marketplace Fee</p>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex w-32 bg-neutral-100 dark:bg-neutral-800 dark:border-neutral-700 items-center rounded-xl border border-neutral-300 overflow-hidden text-gray-500">
          <div className="w-10 h-10 flex-shrink-0 flex flex-col items-center dark:border-neutral-700 border-neutral-300 justify-center border-r">
            <Weth size={18} />
          </div>
          <input
            key={`sansa-price`}
            type="number"
            name={`sansa-price`}
            id={`sansa-price`}
            className="h-10 bg-neutral-100 dark:bg-neutral-800 text-neutral-900 block w-full px-2 dark:placeholder:text-neutral-300 dark:text-white"
            placeholder="Price"
            aria-describedby="price-currency"
            value={price}
            onChange={(e) => {
              const price = e.target.value;
              onPriceChanged?.(price);
            }}
          />
        </div>
        <div
          className={`h-10 ${
            price ? "ml-2" : `w-0`
          } overflow-hidden flex flex-col items-end text-right justify-center transition-all`}
        >
          <p className={`text-neutral-500 dark:text-neutral-400 text-sm`}>
            $
            {price && priceUsd
              ? numberWithCommas(Number(Number(priceUsd).toFixed(2)))
              : " --"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SetDetails;
