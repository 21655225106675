import { Market } from "shared/marketplaces";

export enum Stage {
  SetDetails = "SET_DETAILS",
  Approve = "APPROVE",
  Sign = "SIGN",
  Complete = "COMPLETE",
}

export type StageData = {
  stepTotal?: number;
  stepProgress?: number;
  market?: Market;
};
