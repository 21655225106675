import {
  DEFAULT_CRYPTO_DECIMAL_PRECISION,
  formatBN,
} from "@/client/lib/numbers";

/**
 * Component to formatted a crypto amount
 */
export const FormatCrypto = ({
  amount,
  maximumFractionDigits = 4,
  decimals = DEFAULT_CRYPTO_DECIMAL_PRECISION,
}: {
  amount?: number | string | bigint | null | undefined;
  maximumFractionDigits?: number;
  decimals?: number;
  symbol?: string;
}) => {
  const value = formatBN(amount, maximumFractionDigits, decimals);
  return <p>{value}</p>;
};
