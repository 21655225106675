import { LoginButton } from "client/components/common/LoginButton";

export const ConnectWallet = () => {
  return (
    <>
      <div className="px-8 pb-8 pt-4">
        <LoginButton size="large" className="w-full" />
      </div>
    </>
  );
};

export default ConnectWallet;
