import { CurrencyIcon } from "@/client/components/common/CurrencyIcon";
import { FormatCrypto } from "./FormatCrypto";

/**
 * Component to show the currency icon and formatted amount
 */
export const FormatCryptoCurrency = ({
  amount,
  maximumFractionDigits,
  decimals,
  symbol,
}: {
  amount?: number | string | bigint | null | undefined;
  maximumFractionDigits?: number;
  decimals?: number;
  symbol?: string;
}) => {
  return (
    <span className="flex justify-end items-center font-medium">
      <CurrencyIcon size={16} currencySymbol={symbol} />
      <FormatCrypto
        amount={amount}
        maximumFractionDigits={maximumFractionDigits}
        decimals={decimals}
      />
    </span>
  );
};
