import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  BidModal as ReservoirBidModal,
  BidStep,
} from "@reservoir0x/reservoir-kit-ui";
import { Execute } from "@reservoir0x/reservoir-sdk";

import { useAuth } from "@/client/components/common/AuthProvider";
import { Close } from "@/client/components/icons/close";
import { DateOption } from "@/client/components/common/modal/DateDropdown";
import { getCollectionIdByToken } from "@/shared/reservoir";
import { Asset } from "@/shared/types/asset";
import { readAsset, readCollection } from "@/client/lib/api";
import { Collection } from "@/shared/types/collection";

import { BidComponentBody } from "./BidComponentBody";
import { Stage } from "./stage";

export type ReservoirStepData = {
  totalSteps: number;
  stepProgress: number;
  currentStep: Execute["steps"][0];
};

type Props = {
  tokenId?: string;
  contractAddress?: string;
  open?: boolean;
  setOpen: (open: boolean) => void;
};

export const BidModal = ({
  tokenId,
  contractAddress,
  open = false,
  setOpen,
}: Props) => {
  const handeOnClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={handeOnClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black dark:bg-opacity-75 bg-opacity-25 z-40" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto z-50">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-visible rounded-3xl text-left align-middle shadow-xl transition-all dark:bg-neutral-900 dark:text-white bg-neutral-100">
                  <BidComponent
                    tokenId={tokenId}
                    contractAddress={contractAddress}
                    onClose={handeOnClose}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export const BidComponent = ({
  tokenId,
  contractAddress,
  onClose,
  onSuccess,
}: {
  tokenId?: string;
  contractAddress?: string;
  onClose: () => void;
  onSuccess?: () => void;
}) => {
  const { address } = useAuth();

  const [stage, setStage] = useState<Stage>(Stage.SetDetails);
  const [error, setError] = useState<Error | null>();
  const [stepData, setStepData] = useState<ReservoirStepData | null>();
  const [asset, setAsset] = useState<Asset | null>();
  const [collection, setCollection] = useState<Collection | null>();

  useEffect(() => {
    if (!contractAddress || !tokenId) return;
    refreshAsset();
  }, [tokenId, contractAddress]);

  useEffect(() => {
    if (!asset) return;
    fetchCollection();
  }, [asset]);

  const refreshAsset = async () => {
    if (!contractAddress || !tokenId) return;
    try {
      const result = await readAsset(contractAddress, tokenId);
      setAsset(result);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchCollection = async () => {
    if (!asset?.collectionId) return;

    try {
      const result = await readCollection(asset?.collectionId as string);
      setCollection(result);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCloseClicked = () => {
    if (onClose) onClose();
  };

  const handleOnComplete = () => {
    if (onSuccess) onSuccess();
  };

  return (
    <>
      <div className="p-6 py-4">
        <div className="flex flex-shrink-0 items-center justify-between">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
          >
            {getStageTitle(stage)}
          </Dialog.Title>
          <button
            onClick={handleCloseClicked}
            className="bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 rounded-full w-8 h-8 flex flex-col justify-center items-center"
          >
            <Close />
          </button>
        </div>
      </div>
      <ReservoirBidModal.Custom
        open={true}
        tokenId={tokenId}
        oracleEnabled={false}
        collectionId={
          contractAddress && tokenId
            ? getCollectionIdByToken(contractAddress, tokenId)
            : undefined
        }
      >
        {({
          token,
          collection: resCollection,
          attributes,
          bidStep,
          expirationOption,
          expirationOptions,
          wrappedBalance,
          wrappedContractName,
          wrappedContractAddress,
          bidAmountPerUnit,
          totalBidAmount,
          totalBidAmountUsd,
          quantity,
          setQuantity,
          hasEnoughNativeCurrency,
          hasEnoughWrappedCurrency,
          loading,
          traitBidSupported,
          collectionBidSupported,
          partialBidSupported,
          amountToWrap,
          balance,
          convertLink,
          canAutomaticallyConvert,
          transactionError,
          stepData: stepDataRes,
          bidData,
          currencies,
          currency,
          exchange,
          setCurrency,
          setBidAmountPerUnit,
          setExpirationOption,
          setBidStep,
          setTrait,
          trait,
          placeBid,
        }) => {
          // pick out the top trait floor if available
          const topTraitFloor = Math.max(
            ...(token?.token?.attributes?.map((attr: any) =>
              Number(attr?.floorAskPrice || 0)
            ) || []),
            0
          );

          // filter out the "custom" option from dates since we don't have an input for it
          const filteredExpirationOptions = expirationOptions?.filter(
            (option: DateOption) => option?.value !== "custom"
          );

          useEffect(() => {
            // set the current stage we are on
            switch (bidStep) {
              case BidStep.SetPrice:
                setStage(Stage.SetDetails);
                break;
              case BidStep.Offering:
                if (stepData?.currentStep?.kind === "transaction") {
                  setStage(Stage.Approve);
                } else if (stepData?.currentStep?.kind === "signature") {
                  setStage(Stage.Sign);
                }
                break;
              case BidStep.Complete:
                setStage(Stage.Complete);
                break;
            }

            // if there is an error jump back to the starting stage
            if (error) {
              setStage(Stage.SetDetails);
            }
          }, [bidStep, stepData, error]);

          // if reservoir has an error update ours
          useEffect(() => {
            setError(transactionError);
          }, [transactionError]);

          // if reservoir sets stepData update ours
          useEffect(() => {
            setStepData(stepDataRes);
          }, [stepDataRes]);

          // when completed fire any on success handlers
          useEffect(() => {
            if (stage === Stage.Complete) handleOnComplete();
          }, [stage]);

          const handlePriceChanged = (price: string) => {
            setBidAmountPerUnit(price);
          };

          const handleSubmitOfferClicked = () => {
            if (!hasEnoughWrappedCurrency)
              return alert("Not enough WETH for offer");
            placeBid();
          };

          const handleExpirationDateChanged = (
            expirationOption: DateOption
          ) => {
            setExpirationOption(expirationOption);
          };

          return (
            <BidComponentBody
              tokenId={tokenId}
              contractAddress={contractAddress}
              name={asset?.name}
              artistName={collection?.artistName}
              imageUrl={asset?.imageThumbnailUrl}
              lastPrice={token?.token?.lastSale?.price?.amount?.native}
              collectionFloor={resCollection?.floorAsk?.price?.amount?.native}
              royaltyBps={resCollection?.royalties?.bps}
              topTraitFloor={topTraitFloor}
              topBidPrice={token?.market?.topBid?.price?.amount?.native}
              bidAmount={bidAmountPerUnit}
              bidAmountUsd={totalBidAmountUsd}
              onPriceChanged={handlePriceChanged}
              onSubmitOfferClicked={handleSubmitOfferClicked}
              onExpirationDateChanged={handleExpirationDateChanged}
              expirationDateOptions={filteredExpirationOptions}
              selectedExpirationDateOption={expirationOption}
              stage={stage}
              isFlagged={token?.token?.isFlagged}
              currentUserAddress={address}
              hasEnoughWEth={hasEnoughWrappedCurrency}
              error={error}
            />
          );
        }}
      </ReservoirBidModal.Custom>
    </>
  );
};

const getStageTitle = (stage: Stage) => {
  switch (stage) {
    case Stage.SetDetails:
      return "Make an Offer";
    case Stage.Approve:
      return "Approve Marketplace";
    case Stage.Sign:
      return "Approve Offer";
    case Stage.Complete:
      return "Offer Completed";
  }
};
